import React from 'react';
import styled, { keyframes } from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import Button from '@components/atoms/Button/Button';
import { scrollTo } from '@utils/scrollTo';

const StyledWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  @media (min-width: 1025px) {
    justify-content: flex-start;
  }
`;

const imageAnimation = keyframes`
  0% {
    transform: scale(1) translate(0, 0);
  }
  
  50% {
    transform: scale(1.2) translate(-5%, 5%);
  }
  
  100% {
    transform: scale(1) translate(0, 0);
  }
`;

const StyledImage = styled(GatsbyImage)`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  left: 0;
  top: 0;
  animation: ${imageAnimation} 60s ease infinite;

  @media (min-width: 1025px) {
    animation: ${imageAnimation} 120s ease infinite;
  }
`;

const StyledCentringWrapper = styled.div`
  width: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  flex-direction: column;
  align-items: center;
  padding: 50px 0 70px;

  @media (min-width: 1025px) {
    width: 75%;
    align-items: flex-start;
    padding: 30px 0 50px 4%;
  }

  @media (min-width: 1260px) {
    width: 70%;
  }

  @media (min-width: 1360px) {
    width: 65%;
    padding: 30px 0 50px 7%;
  }

  @media (min-width: 1460px) {
    padding: 30px 0 50px 10%;
  }

  @media (min-width: 1560px) {
    padding: 30px 0 50px 13%;
  }

  @media (min-width: 1680px) {
    padding: 30px 0 50px 17%;
  }
`;

const StyledHeadline = styled.h1`
  color: #fff;
  font-family: ${({ theme }) => theme.secondaryFont};
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.headingS};
  text-align: center;
  margin: 0;
  line-height: 1.1;

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.headingXL};
    display: table-caption;
    word-spacing: 9999rem;
    text-align: left;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 40px;

  @media (min-width: 1025px) {
    margin-top: 20px;
  }
`;

const Hero = () => {
  const result: any = useStaticQuery(query);
  const image = getImage(result.file);

  const handleButtonClick = () => scrollTo('#contact');

  return (
    <StyledWrapper id="hero">
      <StyledImage image={image} alt="Diamentowe Zacisze" />
      <StyledCentringWrapper data-aos="zoom-out">
        <StyledHeadline data-aos="fade-up" data-aos-delay="300">
          Diamentowe Zacisze
        </StyledHeadline>
        <StyledButton icon="phone" onClick={handleButtonClick}>
          Skontaktuj się
        </StyledButton>
      </StyledCentringWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "vis1" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }
  }
`;

export default Hero;
