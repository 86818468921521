import React, { FC } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  box-shadow: 0px 20px 130px #0000000d;
  border-radius: 10px;
  align-items: center;
  width: calc(100% - 20px);
  margin-left: auto;
  height: 90px;
  padding: 15px 30px 15px 0;

  @media (min-width: 1025px) {
    padding: 40px 30px 40px 0;
    height: 185px;
  }
`;

const StyledImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 15px 26px 30px #00000026;
  transform: translateX(-50%);

  @media (min-width: 1025px) {
    width: 80px;
    height: 80px;
  }
`;

const StyledTextWrapper = styled.div``;

const StyledHeadline = styled.h3`
  font-size: ${({ theme }) => theme.bodyS};
  font-weight: ${({ theme }) => theme.regular};
  margin: 0 0 10px 0;

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.bodyXXL};
  }
`;

const StyledParagraph = styled.p`
  font-size: ${({ theme }) => theme.bodyXXS};
  margin: 0;
  color: ${({ theme }) => theme.gray300};

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.bodyS};
  }
`;

const FinishingStandard: FC<Props> = ({
  icon,
  headline,
  children,
  className,
  ...props
}) => (
  <StyledWrapper className={className} {...props}>
    <StyledImg alt="" aria-hidden="true" src={icon} />
    <StyledTextWrapper>
      <StyledHeadline>{headline}</StyledHeadline>
      <StyledParagraph>{children}</StyledParagraph>
    </StyledTextWrapper>
  </StyledWrapper>
);

interface Props {
  icon: string;
  headline: string;
  className?: string;
}

export default FinishingStandard;
