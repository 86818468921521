import React, { FC } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Button from '@components/atoms/Button/Button';

const StyledWrapper = styled.div`
  position: relative;
  margin-bottom: 120px;

  :last-of-type {
    margin-bottom: 80px;
  }

  @media (min-width: 1025px) {
    width: 65%;
    max-width: 1266px;
    margin-bottom: 250px;

    :nth-of-type(even) {
      margin-right: auto;
    }

    :nth-of-type(odd) {
      margin-left: auto;
    }

    :last-of-type {
      margin-bottom: 250px;
    }
  }
`;

const StyledHorizontalText = styled.span`
  display: none;

  @media (min-width: 1025px) {
    display: block;
    font-size: ${({ theme }) => theme.bodyXS};
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.gray300};
    position: absolute;
    right: -20px;
    top: 0;
    width: 950px;
    height: 10px;
    transform: rotate(-90deg);
    transform-origin: top right;
    text-align: right;

    ${StyledWrapper}:nth-of-type(odd) & {
      right: unset;
      left: -20px;
      transform-origin: top left;
      text-align: right;
      transform: rotate(90deg);
    }
  }
`;

const StyledImg = styled(GatsbyImage)`
  @media (min-width: 1025px) {
    height: 950px;
  }
`;

const StyledBox = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.background};
  width: 40%;
  min-width: 180px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: column;
  min-height: 80px;
  padding: 10px;

  @media (min-width: 1025px) {
    min-height: 240px;
    min-width: 530px;
    justify-content: center;

    ${StyledWrapper}:nth-of-type(even) & {
      left: 0;
      transform: translate(0, 20%);
    }

    ${StyledWrapper}:nth-of-type(odd) & {
      top: 0;
      right: 0;
      bottom: unset;
      left: unset;
      transform: translate(0, -20%);
    }
  }
`;

const StyledHeadline = styled.h3`
  font-size: ${({ theme }) => theme.bodyXS};
  margin: 0;
  font-weight: ${({ theme }) => theme.bold};
  color: ${({ theme }) => theme.primary};
  text-align: center;

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.headingXS};
  }
`;

const StyledParagraph = styled.p`
  font-size: ${({ theme }) => theme.bodyXS};
  margin: 5px 0 0;
  text-align: center;

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.headingXS};
  }
`;

const StyledButton = styled(Button)`
  font-size: ${({ theme }) => theme.bodyXXS};
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
`;

const Investment: FC<Props> = ({ img, headline, children, ...props }) => (
  <StyledWrapper {...props}>
    <StyledHorizontalText>{headline}</StyledHorizontalText>
    <StyledImg image={getImage(img)} alt={headline} />
    <StyledBox>
      <StyledHeadline>{headline}</StyledHeadline>
      <StyledParagraph>{children}</StyledParagraph>
      <StyledButton disabled>Oferta nieaktualna</StyledButton>
    </StyledBox>
  </StyledWrapper>
);

interface Props {
  img: any;
  headline: string;
}

export default Investment;
