export const firstText = `Diamentowe Zacisze Rząska to <b>prestiżowe</b> osiedle 6 jednorodzinnych domów 
    wolnostojących z własnym ogrodem i garażem, znajdujących się w samym sercu prężnie <b>rozwijającej</b> 
    się miejscowości Rząska tuż za granicą Krakowa. <b>Nowoczesny</b> styl domów połączony z podwyższonym 
    standardem sprawi, że przekona on do siebie najbardziej wymagających klientów.
`;

export const secondText = `Osiedle Diamentowe Zacisze Rząska to <b>idealne miejsce</b> dla rodzin
  z małymi dziećmi, dla których mieszkanie w bloku stało się
  niekomfortowe i szukają własnego <b>wygodnego</b> lokum. Inwestycja
  powstała w zacisznym miejscu, blisko natury, równocześnie jest
  doskonale skomunikowana z Krakowem oraz autostradą A4 i lotniskiem w Balicach.
`;
