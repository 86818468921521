import React from 'react';
import styled from 'styled-components';
import Headline from '@components/atoms/Headline/Headline';
import { graphql, useStaticQuery } from 'gatsby';
import Investment from '@components/molecules/OurInvestments/Investment/Investment';

const StyledWrapper = styled.section`
  width: 100%;
  background: #1a1a18;
  color: ${({ theme }) => theme.gray400};
  padding: 35px 0;
  margin-top: 35px;
  position: relative;
  overflow: hidden;

  @media (min-width: 1025px) {
    padding-top: 300px;
  }
`;

const StyledInnerWrapper = styled.div`
  width: 85%;
  max-width: 620px;
  margin: auto;
  overflow: hidden;

  @media (min-width: 1025px) {
    width: 100%;
    max-width: unset;
  }
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 45px;

  @media (min-width: 1025px) {
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: top right;
    right: 10%;
    top: 10%;
    width: 900px;
    margin: 0;
  }
`;

const OurInvestments = () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(query);

  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledHeadline secondary id="our_investments">
          Nasze inwestycje
        </StyledHeadline>

        <Investment
          img={nodes[0]}
          headline="Kraków Stare Dębniki "
          data-aos="fade-right"
        >
          15 lokali inwestycyjnych
        </Investment>

        <Investment
          img={nodes[1]}
          headline="Kraków ul. Litewska"
          data-aos="fade-left"
        >
          Mieszkanie 50m2
        </Investment>

        <Investment
          img={nodes[2]}
          headline="Kraków ul. Łokietka"
          data-aos="fade-right"
        >
          Mieszkanie 60m2
        </Investment>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "otherInvestments" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            placeholder: BLURRED
            formats: [WEBP, JPG]
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
`;

export default OurInvestments;
